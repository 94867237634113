.outer_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.outer_container .inner_content_container {
  flex: 2;
  position: relative;
  overflow-y: scroll;
}
.outer_container .inner_content_container .reading_content {
  max-height: 400px;
  overflow-y: scroll;
}
.outer_container .nav_scroll {
  top: 0;
  z-index: 100;
  white-space: nowrap;
  box-sizing: border-box;
  background: white;
  border-top: solid 2px #f5f5f5;
  height: 50px;
  line-height: 50px;
  width: 100%;
  overflow-x: scroll;
}
.outer_container .nav_scroll.fixed_title {
  position: fixed;
  top: 136px;
  left: 5%;
  width: 90%;
  border-bottom: solid 1px #f5f5f5;
}
.outer_container .nav_scroll .nav_title {
  display: inline-template;
  text-align: center;
  padding: 0 20px;
}
.outer_container .empty_nav {
  height: 50px;
}
.content_box {
  padding-top: 70px;
}
