.item_card {
  padding: 20px 30px;
  position: relative;
  background-color: white;
  border-top: dashed 2px #ddd;
}
.item_card .question_number {
  position: absolute;
  top: 0;
  left: 0;
  width: 35px;
  height: 30px;
  color: white;
  font-size: 16px;
  text-align: left;
  border-bottom-right-radius: 40px;
  padding-left: 10px;
  box-sizing: border-box;
}
.dashed_line {
  border-top: dashed 2px #ddd;
  margin-top: 10px;
  padding-top: 10px;
}
.user_answer {
  padding-left: 20px;
}
