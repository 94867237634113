.preview_img_box {
  text-align: left;
  position: relative;
  display: inline-block;
}
.preview_img_box .preview_img {
  width: 200px;
}
.preview_img_box .preview_btn {
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 0rpx 20rpx;
  font-size: 28rpx;
}
