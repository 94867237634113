









































.reading_content {
  text-indent: 2em;
  text-align: left;
  font-size: 16px;
}
