





































.img-box {
  text-align: center;
}
