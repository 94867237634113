















































































































































@import './index.less';
.radio_group {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  .label_item {
    margin-bottom: 20px;
    width: 50%;
    .anticon-yyashow {
      margin-right: 20px;
    }
    &.flex {
      display: flex;
    }
    .radio_img {
      width: 100px;
      margin-left: 20px;
    }
  }
}
