























































































































.ant-radio-group {
  display: block;
  width: 100%;
  .ant-radio-wrapper {
    font-size: 16px;
  }
}

.radio_outer {
  text-align: left;
  padding-left: 20px;
  .radio_item {
    display: block;
    margin-top: 10px;
    .item_index {
      margin-left: 20px;
      margin-right: 15px;
      vertical-align: middle;
      font-size: 16px;
    }
    .icon_img {
      width: 26px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
